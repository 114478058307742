/**
 * hides/shows fieldsets based on a data-attribute set in the form.yaml files.
 * see file EXT:theme/Resources/Private/Forms/PartnerKlubPunkte.form.yaml
 * see file EXT:theme/Resources/Private/Forms/GutscheinaktionX5Redeen.form.yaml
 * this defines, which radio value should toggle which fieldset name
 * dataAttribute: e.g.: "data-toggle='{ \"punkte\":\"fieldset-points-reward\"  }'"
 */

export class FormToggle {
    constructor() {
        this.fileUploadHandler('.form-file');
    }

    toggleHandler(formIdentifier: string, selectToReset: boolean) {
        const form: HTMLFormElement | null = document.querySelector(`[id*='${formIdentifier}']`);
        const formId: string | undefined = form?.id;
        const toggle: NodeListOf<HTMLDivElement> | undefined = form?.querySelectorAll('[data-toggle]');

        toggle?.forEach((elem: HTMLDivElement) => {
            const data = JSON.parse(elem.dataset.toggle ?? '{}');
            const inputs: NodeListOf<HTMLInputElement> | null = elem.querySelectorAll('input[type="radio"]');

            const changeSelectfield = (elem: HTMLFieldSetElement | null, selectedIndex: number, required: boolean) => {
                const selectField: NodeListOf<HTMLSelectElement> | undefined = elem?.querySelectorAll('select');
                selectField?.forEach((field: HTMLSelectElement) => {
                    if (selectedIndex === 0) {
                        field.selectedIndex = selectedIndex;
                    }

                    if (!required) {
                        field.removeAttribute('required');
                    } else {
                        field.setAttribute('required', 'required');
                    }
                });
            };

            const resetInput = (elem: HTMLFieldSetElement | null, required: boolean) => {
                const inputs: NodeListOf<HTMLInputElement> | undefined = elem?.querySelectorAll('input');
                inputs?.forEach((input: HTMLInputElement) => {
                    if (!required) {
                        input.removeAttribute('required');
                        input.setAttribute('disabled', 'disabled');
                    } else {
                        input.setAttribute('required', 'required');
                        input.removeAttribute('disabled');
                    }
                });
            };

            const hideFieldset = () => {
                // @ts-ignore
                for (const [key, value] of Object.entries(data)) {
                    const toggleElem: HTMLFieldSetElement | null = document.querySelector(
                        '[id*="' + formId + '-' + value + '"]'
                    );
                    toggleElem?.classList.add('d-none');
                    if (selectToReset) {
                        changeSelectfield(toggleElem, 0, false);
                    } else {
                        resetInput(toggleElem, false);
                    }
                }
            };

            hideFieldset();

            inputs.forEach((input: HTMLInputElement) => {
                if (input.checked) {
                    if (data[input.value.toLowerCase()]) {
                        const toggleElem = document.querySelector(
                            '[id*="' + formId + '-' + data[input.value.toLowerCase()] + '"]'
                        ) as HTMLFieldSetElement;

                        toggleElem?.classList.remove('d-none');
                    }
                }

                const _fileInputRefernce = form!.querySelectorAll('input[type="hidden"][id*="file-reference"]');
                _fileInputRefernce.forEach((input) => {
                    const _preview = input.nextElementSibling as HTMLDivElement;
                    if (_preview.classList.contains('d-none')) {
                        input.setAttribute('disabled', 'disabled');
                    } else {
                        input.removeAttribute('disabled');
                    }
                });

                input.addEventListener('change', () => {
                    hideFieldset();

                    if (data[input.value.toLowerCase()]) {
                        const toggleElem = document.querySelector(
                            '[id*="' + formId + '-' + data[input.value.toLowerCase()] + '"]'
                        ) as HTMLFieldSetElement;
                        toggleElem?.classList.remove('d-none');

                        if (selectToReset) {
                            changeSelectfield(toggleElem, 1, true);
                        } else {
                            this.previewHandler(toggleElem.querySelector('.form-file')!, false);
                            resetInput(toggleElem, true);
                        }
                    } else {
                        const _fileInputRefernce = form!.querySelectorAll('input[type="hidden"][id*="file-reference"]');
                        _fileInputRefernce.forEach((input) => {
                            const _preview = input.nextElementSibling as HTMLDivElement;
                            if (_preview.classList.contains('d-none')) {
                                input.setAttribute('disabled', 'disabled');
                            } else {
                                input.removeAttribute('disabled');
                            }
                        });
                    }
                });
            });
        });
    }

    previewHandler(_el: HTMLButtonElement, show: boolean) {
        const _input = _el.querySelector('input[type="file"]') as HTMLInputElement;
        const _preview = _el.querySelector('.preview-wrapper');
        const _label = _el.previousElementSibling;

        if (show) {
            _preview?.classList.remove('d-none');
            _input?.classList.add('d-none');
            _input?.removeAttribute('required');
            _input?.setAttribute('disabled', 'disabled');
            _label?.classList.add('d-none');
        } else {
            _preview?.classList.add('d-none');
            _input?.classList.remove('d-none');

            if (_input?.dataset.required) {
                _input?.setAttribute('required', 'required');
            }

            _input?.removeAttribute('disabled');
            _label?.classList.remove('d-none');
        }
    }

    fileUploadHandler(inputWrapperSelector: string): void {
        const formUploads: NodeListOf<HTMLButtonElement> = document.querySelectorAll(inputWrapperSelector);

        formUploads.forEach((_el) => {
            const _preview = _el.querySelector('.preview-wrapper');
            const _previewRemoveButton = _preview?.querySelector('button');

            if (_preview) {
                this.previewHandler(_el, true);
            } else {
                this.previewHandler(_el, false);
            }

            if (_previewRemoveButton) {
                _previewRemoveButton.addEventListener('click', () => {
                    this.previewHandler(_el, false);
                });
            }
        });
    }
}
